// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import MainLayout from "components/Layouts/MainLayout";
import { Box, Grid, Skeleton, Stack } from "@mui/material";
import { NormalText } from "components/styled/text.styled";
import { Button } from "components/styled/button.styled";
import { SpacerLine } from "components/styled/layout.styled";
import { ArrowLeftIcon, CircleQuestIcon } from "assets/icons";
import styled from "styled-components";
import useRedux from "redux/useRedux";
import { getTotalProduct, resetCart } from "redux/cart/cart.reducers";
import ProductItems from "./product.items";
import Toggle from "components/Form/Toggle";
import PaymentItem from "./payment.item";
import InputField from "components/Form/InputField";
import { useNavigate } from "react-router-dom";
import { formatCurrency, localStorageSupport, notify } from "helper/general";
import {
  getFeeSetting,
  IFeeSetting,
  getPlatformfee,
} from "redux/setting/feesetting.services";
import { checkMember } from "redux/loyalty";
import {
  getPaymentMethodList,
  IPaymentMethod,
  getAvailableBanks,
  BankProps,
} from "redux/paymentmethod";
import {
  CheckoutPaymentDetailProps,
  CheckoutProductAddonsProps,
  CheckoutProductProps,
  CheckoutProps,
  checkoutOrder,
  getOrderInvoice,
} from "redux/checkout";
import Loader from "components/Loader";
import ConfirmationModal from "components/Confirmation";
import { BillConfirmationIcon } from "assets/icons";
import QRView from "./qr.view";
import PaymentView from "./va.view";
import BottomSheet from "components/BottomSheet";
import TimesIcon from "assets/icons/ic_times.svg";
import BankItem from "./bank.item";

const CheckoutPage = () => {
  const navigate = useNavigate();

  const {
    storeState: { Cart, Auth, StoreInfo },
    thunkDispatch,
  } = useRedux();
  const [qrPayload, setQrPayload] = React.useState({
    order_number: "",
    qr_string: "",
  });
  const [vaPayload, setVaPayload] = React.useState({
    payment_method: "",
    payment_string: "",
  });
  const [showQris, setShowQris] = React.useState(false);
  const [showVirtualAkun, setShowVirtualAkun] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [cancelModal, setCancelModal] = React.useState(false);
  const [pickBank, setPickBank] = React.useState(false);
  const [availableBanks, setAvailableBanks] = React.useState<BankProps[]>();
  const [message, setMessage] = React.useState("Mohon tunggu");
  const [paymentMethod, setPaymentMethod] = React.useState("");
  const [taxFee, setTaxFee] = React.useState(0);
  const [serviceFee, setServiceFee] = React.useState(0);
  const [subTotal, setSubtotal] = React.useState(0);
  const [grandTotal, setGrandTotal] = React.useState(0);
  const [feeSetting, setFeeSetting] = React.useState<IFeeSetting>();
  const [platformFee, setPlatformFee] = React.useState<any>();
  const [paymentLoading, setPaymentLoading] = React.useState(true);
  const [paymentMethodList, setPaymentMetodList] =
    React.useState<IPaymentMethod[]>();
  // const [memberInfo, setMemberInfo] = React.useState<IMember>();
  const [loyaltyAmount, setLoyaltyAmount] = React.useState(0);
  const [useLoyalty, setUseLoyalty] = React.useState(false);
  const [loyaltyUsage, setLoyaltyUsage] = React.useState(0);
  const [memberPayload, setMemberPayload] = React.useState({
    full_name: "",
    phone_number: "",
    // email: '',
  });
  const [totalCash, setTotalCash] = React.useState("");
  const [noRef, setNoRef] = React.useState("");
  // Checkout Payload
  const [services, setServices] = React.useState({
    payment_fee: 0,
    tax: 0,
    service: 0,
  });
  const [paymentDetailCheckout, setPaymentDetailCheckout] =
    React.useState<CheckoutPaymentDetailProps>({
      payment_type: "",
      payment_channel: "",
      tax_percentage: 0,
      service_percentage: 0,
      tax_price: 0,
      service_price: 0,
      payment_fee_type: "OWNER",
      payment_fee_customer: 0,
      payment_fee_owner: 0,
      total_amount: 0,
      bank_code: "",
    });

  const [submit, setSubmit] = React.useState(false);
  const [transactionFee, setTransactionFee] = React.useState<any>("0");
  const [payment_fee, setPayment_fee] = React.useState<any>(0);
  const [paymentType, setPaymentType] = React.useState<any>("");

  React.useEffect(() => {
    if (Cart && Cart.items) {
      let total = 0;
      Cart.items.forEach((cart) => {
        total += cart.qty * cart.price;
        if (cart && cart.additionals && cart.additionals.length) {
          let addonsTotal = 0;
          cart.additionals.forEach((add) => {
            addonsTotal += add.qty * add.price * cart.qty;
          });
          total += addonsTotal;
        }
      });

      let paymentFee = services.payment_fee;
      let customerFee: number = 0;
      let ownerFee: number = paymentFee;
      let tax = 0;
      let service = 0;
      let result = 0;
      let canUsedAmount = 0;

      if (feeSetting) {
        if (feeSetting.payment_fee_type === "SPLIT") {
          customerFee = paymentFee / 2;
          ownerFee = paymentFee / 2;
        } else if (feeSetting.payment_fee_type === "CUSTOMER") {
          customerFee = paymentFee;
        } else {
          ownerFee = paymentFee;
        }

        if (useLoyalty) {
          if (loyaltyAmount >= total) {
            canUsedAmount = total - 10000;
          } else {
            var maximum = total - 10000;
            if (maximum > 10000) {
              canUsedAmount = maximum;
            } else {
              canUsedAmount = loyaltyAmount;
            }
          }
        }

        if (feeSetting && feeSetting.id) {
          if (feeSetting.tax) {
            tax = (total - canUsedAmount) * (feeSetting.tax / 100);
          }

          if (feeSetting.service) {
            service = (total - canUsedAmount) * (feeSetting.service / 100);
          }

          if (feeSetting.payment_fee_type) {
            setPaymentDetailCheckout({
              ...paymentDetailCheckout,
              payment_fee_type: feeSetting.payment_fee_type,
            });
          }
        }
      }

      customerFee = Math.ceil(customerFee);
      canUsedAmount = Math.ceil(canUsedAmount);
      ownerFee = Math.ceil(ownerFee);
      service = Math.ceil(service);
      tax = Math.ceil(tax);
      total = Math.ceil(total);
      setPaymentDetailCheckout({
        ...paymentDetailCheckout,
        payment_fee_customer: customerFee,
        payment_fee_owner: ownerFee,
      });

      // console.log(
      //   "useefect payment fee",
      //   paymentDetailCheckout.payment_fee_customer
      // );

      getPlatformfee(total).then((res) => {
        if (res) {
          setPlatformFee(res.data);
        }
      });

      setPayment_fee(
        transactionFee?.payment_fee_percentage > 0
          ? Math.ceil((subTotal * transactionFee?.payment_fee_percentage) / 100)
          : transactionFee?.payment_fee_price
      );

      result = total + tax + service + customerFee - canUsedAmount;

      setLoyaltyUsage(canUsedAmount);
      setTaxFee(tax);
      setServiceFee(service);
      setSubtotal(total);
      setGrandTotal(result ?? 0);
    }
  }, [feeSetting, services, Cart, useLoyalty, payment_fee, grandTotal]); //eslint-disable-line

  const handleCheckMember = () => {
    setSubmit(true);
    if (memberPayload.phone_number.length <= 13) {
      thunkDispatch(checkMember(memberPayload))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            // setMemberInfo(res.data.data);
            let msg = "";
            if (res.data.data !== null) {
              if (res.data.data.loyalty_point === 0) {
                msg = "Tidak ada poin";
              } else {
                msg = "Poin bisa digunakan";
                setLoyaltyAmount(Number(res.data.data.loyalty_point));
              }
              console.log("L", res.data.data.loyalty_point);
            } else {
              msg = "Tidak ada poin";
            }
            notify("success", msg);
          } else if (res && res.status === "error") {
            if (res.error && res.error.response) {
              notify("error", res.error.response.data.message);
            }
          }
        })
        .catch((err) => notify("error", err.message));
    }
  };

  const handleCheckout = async () => {
    setMessage("Mohon Tunggu");
    setLoading(true);
    //set mandatory input reference for payment method debit/kredit
    if (paymentMethod === "debit" || paymentMethod === "credit") {
      if (noRef === "") {
        notify("error", "Silahkan isi terlebih dahulu nomer referensi");
        setLoading(false);
        return;
      }
      if (paymentDetailCheckout.bank_code === "") {
        notify("error", "Silahkan pilih bank terlebih dahulu");
        setLoading(false);
        return;
      }
    }
    //set mandatory input jumlah cash for cash method
    if (paymentMethod === "cash" && totalCash === "") {
      notify("error", "Silahkan isi terlebih dahulu jumlah cash");
      setLoading(false);
      return;
    }
    // check total input cash more than grand total
    if (
      paymentMethod === "cash" &&
      totalCash !== "" &&
      Number(totalCash) < grandTotal
    ) {
      notify("error", "Jumlah Cash tidak boleh kurang dari Grand Total");
      setLoading(false);
      return;
    }

    if (Cart && Cart.items && Cart.items.length > 0) {
      if (paymentMethod) {
        let products: CheckoutProductProps[] = [];
        Cart.items.forEach((product) => {
          let addons: CheckoutProductAddonsProps[] = [];
          if (product.additionals) {
            product.additionals.forEach((addon) => {
              addons.push({
                product_id: addon.product_id,
                product_assign_id: Number(addon.id),
                product_price: Number(addon.price),
                product_qty: addon.qty,
                sub_total: Number(addon.price) * addon.qty,
                notes: "",
              });
            });
          }

          products.push({
            product_id: product.product_id,
            product_price: Number(product.price),
            product_qty: product.qty,
            sub_total: Number(product.price) * product.qty,
            is_customizable: product.is_customizable,
            notes: "",
            product_add_on: addons,
          });
        });

        console.log("type payment", paymentType);
        console.log("type payment 2", paymentDetailCheckout.payment_fee_type);

        let payload: CheckoutProps = {
          store_code:
            StoreInfo &&
            StoreInfo.data &&
            StoreInfo.data.data &&
            StoreInfo.data.data.store_code
              ? StoreInfo.data.data.store_code
              : "",
          device_type: "MINIPOS",
          order_type: "",
          payment_detail: {
            payment_channel: paymentMethod,
            payment_type: paymentMethod,
            tax_percentage: feeSetting?.tax ?? 0,
            service_percentage: feeSetting?.service ?? 0,
            tax_price: taxFee,
            service_price: serviceFee,
            payment_fee_customer:
              transactionFee?.payment_fee_percentage > 0
                ? Math.ceil(
                    (subTotal * transactionFee?.payment_fee_percentage) / 100
                  ) ?? 0
                : transactionFee?.payment_fee_price,
            payment_fee_owner: paymentDetailCheckout.payment_fee_owner,
            payment_fee_type: paymentType,
            total_amount: grandTotal,
            bank_code: paymentDetailCheckout.bank_code,
          },
          sub_total: subTotal,
          product_order_detail: products,
          total_price: grandTotal,
          use_loyalty: useLoyalty,
          loyalty_usage: loyaltyUsage,
          customer_name: memberPayload.full_name,
          customer_phone_number: memberPayload.phone_number,
          // customer_email: memberPayload.email,
          reference_number: noRef,
          use_voucher: false,
          voucher_id: [],
        };

        await thunkDispatch(checkoutOrder(payload))
          .unwrap()
          .then(async (res) => {
            if (res && res.status === "success") {
              await thunkDispatch(
                getOrderInvoice({
                  order_number: res.data.data.order_number,
                })
              )
                .unwrap()
                .then(async (inv) => {
                  if (inv && inv.status === "success") {
                    if (res.data.data.payment_method === "virtual_account") {
                      console.log(res);
                      setVaPayload({
                        payment_string:
                          res.data.data.metadata.account_number ?? "",
                        payment_method: res.data.data.payment_method,
                      });
                      // setShowPaymentFailed(false);
                      setShowVirtualAkun(true);
                      setLoading(false);
                    } else if (res.data.data.payment_method === "qr_code") {
                      setMessage("Menampilkan QRIS");
                      setQrPayload({
                        qr_string: res.data.data.metadata.qr_code,
                        order_number: res.data.data.order_number,
                      });
                      setShowQris(true);
                      if (localStorageSupport()) {
                        localStorage.setItem(
                          "qr_payload",
                          JSON.stringify({
                            qr_string: res.data.data.metadata.qr_code,
                            order_number: res.data.data.order_number,
                          })
                        );
                      }
                      setLoading(false);
                    } else {
                      setLoading(false);
                      thunkDispatch(resetCart());
                      navigate("/success");
                    }
                  } else if (inv && inv.status === "error") {
                    if (inv.error.response) {
                      notify("error", inv.error.response.data.message);
                    }
                  }
                })
                .catch((err) => {
                  if (err.response) {
                    notify("error", err.response.data.message);
                    setLoading(false);
                  } else {
                    notify("error", err.message);
                    setLoading(false);
                  }
                });
            } else if (res && res.status === "error") {
              notify("error", res.error.response.data.message);
              setLoading(false);
            }
          })
          .catch((err) => {
            if (err.response) {
              notify("error", err.response.data.message);
              setLoading(false);
            } else {
              notify("error", err.message);
              setLoading(false);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        notify("warning", "Silahkan pilih metode pembayaran terlebih dahulu");
        setLoading(false);
      }
    } else {
      notify("warning", "Silahkan pilih produk terlebih dahulu");
      setLoading(false);
      navigate("/product");
    }
  };

  React.useEffect(() => {
    setPaymentLoading(true);
    if (Auth && Auth.status === "success") {
      if (
        Auth.data &&
        Auth.data.profile_data &&
        Auth.data.profile_data.store_access &&
        Auth.data.profile_data.store_access.merchant_id
      ) {
        thunkDispatch(
          getPaymentMethodList(Auth.data.profile_data.store_access.merchant_id)
        )
          .unwrap()
          .then((res) => {
            if (res && res.status === "success") {
              setPaymentLoading(false);
              setPaymentMetodList(res.data.data);
            } else if (res && res.status === "error") {
              if (res.error.response) {
                notify("error", res.error.response.data.message);
              }
            }
          })
          .catch((e) => {
            notify("error", e.message);
          });

        getFeeSetting(Auth.data.profile_data.store_access.merchant_id).then(
          (res) => {
            if (res && res.id) {
              setFeeSetting(res);
              setPaymentType(res.payment_fee_type);
            }
          }
        );
      }
    }
  }, [Auth, thunkDispatch]);

  const getCartTotalPrice = async () => {
    let total = 0;
    if (Cart) {
      await Cart.items.forEach((cart) => {
        total += cart.qty * cart.price;
        if (cart && cart.additionals && cart.additionals.length) {
          let addonsTotal = 0;
          cart.additionals.forEach((add) => {
            addonsTotal += add.qty * add.price * cart.qty;
          });
          total += addonsTotal;
        }
      });
    }

    return total;
  };

  React.useEffect(() => {
    thunkDispatch(getTotalProduct()).unwrap();
  }, [Cart, thunkDispatch]);

  React.useEffect(() => {
    getAvailableBanks().then((res) => {
      if (res && res.length > 0) {
        setAvailableBanks(res);
      }
    });
  }, []);

  React.useEffect(() => {
    // check if qrPayload exist on localStorage
    try {
      let qrPayload = localStorageSupport()
        ? JSON.parse(localStorage.getItem("qr_payload") || "")
        : null;

      if (qrPayload) {
        setShowQris(true);
        setQrPayload(qrPayload);
      }
    } catch (err) {
      console.log("err", err);
    }

    return () => {
      localStorage.removeItem("qr_payload");
    };
  }, []);

  return (
    <MainLayout hideBackgroundImage>
      <ConfirmationModal
        icon={BillConfirmationIcon}
        open={cancelModal}
        title={"Apakah transaksi ini akan dibatalkan..?"}
        handleClose={() => setCancelModal(false)}
        handleConfirm={() => navigate("/product")}
      />

      {showQris && qrPayload.order_number && qrPayload.qr_string ? (
        <QRView {...qrPayload} />
      ) : showVirtualAkun &&
        vaPayload.payment_method &&
        vaPayload.payment_string ? (
        <PaymentView metadata={vaPayload} show={showVirtualAkun} />
      ) : (
        <Box width={"100%"}>
          {/* Select Bank */}
          {pickBank && (
            <BottomSheet
              zindex="3"
              borderradius="10px 10px 0 0"
              height="auto"
              padding="unset"
            >
              <Box padding={"15px 20px"}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <NormalText
                    textAlign="left"
                    fontFamily="D-DIN Exp"
                    fontWeight="bold"
                    fontSize="14px"
                  >
                    Pilih Bank
                  </NormalText>
                  <img
                    src={TimesIcon}
                    style={{ cursor: "pointer" }}
                    onClick={() => setPickBank(false)}
                    alt={"ic_close"}
                  />
                </Stack>
              </Box>
              <SpacerLine height="2px" />
              <Box
                margin={"10px 0 0 0"}
                padding={"0 10px"}
                sx={{ overflowY: "auto" }}
              >
                {availableBanks &&
                  availableBanks.length > 0 &&
                  availableBanks.map(
                    (bank, index) =>
                      bank.is_activated && (
                        <BankItem
                          key={index}
                          code={bank.code}
                          name={bank.name}
                          background={"#b92362"}
                          active={paymentDetailCheckout.bank_code === bank.code}
                          onClick={() => {
                            setPaymentDetailCheckout({
                              ...paymentDetailCheckout,
                              bank_code: bank.code,
                            });
                            // setPickPayment(false);
                            setPickBank(false);
                          }}
                        />
                      )
                  )}
              </Box>
            </BottomSheet>
          )}

          <Box width={"100%"} margin={"15px 16px"}>
            <Grid container direction={"row"}>
              <div onClick={() => navigate("/cart")} style={{ width: "10%" }}>
                <img
                  src={ArrowLeftIcon}
                  alt={"arrow_left_icon"}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div
                style={{
                  width: "90%",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  display: "flex",
                }}
              >
                <NormalText
                  fontWeight="bold"
                  fontSize={"20px"}
                  textAlign={"center"}
                  margin={"0 0 0 -70px"}
                  wordWrap={"normal"}
                >
                  Transaksi Detail
                </NormalText>
              </div>
            </Grid>
          </Box>
          <ContainerProduct>
            {/* Customer Info */}
            <Box padding={"0 15px"} margin={"0 0 10px 0"}>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={3.5} md={3.5}>
                  <NormalText fontSize={"13px"} style={{ width: "155px" }}>
                    Nama Pemesan
                  </NormalText>
                </Grid>
                <Grid item xs={8.5} md={8.5}>
                  <InputField
                    placeholder="Masukan nama pemesan"
                    value={memberPayload.full_name}
                    onChange={(e: any) =>
                      setMemberPayload({
                        ...memberPayload,
                        full_name: e.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={3.5} md={3.5}>
                  <NormalText fontSize={"13px"} style={{ width: "155px" }}>
                    No.HP Pemesan
                  </NormalText>
                </Grid>
                <Grid item xs={8.5} md={8.5}>
                  <InputField
                    placeholder="Masukan nomor hp pemesan"
                    type={"number"}
                    value={memberPayload.phone_number}
                    onChange={(e: any) => {
                      setMemberPayload({
                        ...memberPayload,
                        phone_number: e.target.value,
                      });
                    }}
                    validationText={
                      submit && memberPayload.phone_number.length > 13
                        ? "Nomor Pemesanan tidak boleh lebih dari 13 karakter"
                        : ""
                    }
                    validationType={
                      submit && memberPayload.phone_number.length > 13
                        ? "error"
                        : undefined
                    }
                  />
                </Grid>
              </Grid>
              {/* { paymentMethod &&
                paymentMethod === "virtual_account" && (
                  <Grid container spacing={2} alignItems={'center'}>
                    <Grid item xs={3.5} md={3.5}>
                      <NormalText fontSize={'13px'} style={{ width: '155px' }}>
                        Email Pemesan
                      </NormalText>
                    </Grid>
                    <Grid item xs={8.5} md={8.5}>
                      <InputField
                        placeholder="Masukan email pemesan"
                        value={memberPayload.email}
                        onChange={(e: any) =>
                          setMemberPayload({
                            ...memberPayload,
                            email: e.target.value,
                          })
                        }
                      />
                    </Grid>
                  </Grid>     
              )} */}
              {/* <Grid container spacing={1} alignItems={'center'}>
                <Grid item xs={3.5} md={3.5}></Grid>
                <Grid item xs={8.5} md={8.5}>
                  <CheckButton onClick={() => handleCheckMember()}>
                    <Stack direction={'row'} spacing={1}>
                      <NormalText fontSize={'15px'}>Cek Member</NormalText>
                      <img src={CircleQuestIcon} alt={'circle_quest_ic'} />
                    </Stack>
                  </CheckButton>
                </Grid>
              </Grid> */}
            </Box>
            {/* Order Items */}
            <Box>
              <ProductItems />
            </Box>
            {/* Order Summaries */}
            <Box padding={"0 15px"} margin={"10px 0"}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <NormalText fontSize="15px">Sub Total</NormalText>
                <NormalText fontSize="15px" fontWeight={"bold"}>
                  {formatCurrency(subTotal)}
                </NormalText>
              </Stack>
              {/* <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <NormalText fontSize="15px">Biaya platform</NormalText>
                <NormalText fontSize="15px" >
                  {formatCurrency(platformFee ?? 0)}
                </NormalText>
              </Stack> */}
              {useLoyalty && (
                <Stack
                  margin={"7px 0"}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <NormalText fontSize="15px">Poin Digunakan</NormalText>
                  <NormalText fontSize="15px">
                    - {formatCurrency(loyaltyUsage)}
                  </NormalText>
                </Stack>
              )}
              <Stack
                direction={"row"}
                margin={"7px 0"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <NormalText fontSize="15px">
                  Tax {String(feeSetting?.tax ?? 0)}%
                </NormalText>
                <NormalText fontSize="15px">
                  {formatCurrency(taxFee)}
                </NormalText>
              </Stack>
              <Stack
                margin={"7px 0"}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <NormalText fontSize="15px">
                  Services {String(feeSetting?.service ?? 0)}%
                </NormalText>
                <NormalText fontSize="15px">
                  {formatCurrency(serviceFee)}
                </NormalText>
              </Stack>
              {paymentType !== "OWNER" ? (
                <Stack
                  margin={"7px 0"}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <NormalText fontSize="15px">Biaya Pembayaran</NormalText>
                  <NormalText fontSize="15px">
                    {/* {formatCurrency(services.payment_fee)} */}
                    {transactionFee?.payment_fee_percentage > 0 ? (
                      <p>
                        {formatCurrency(
                          Math.ceil(
                            (subTotal *
                              transactionFee?.payment_fee_percentage) /
                              100
                          ) ?? 0
                        )}
                      </p>
                    ) : (
                      <p>
                        {formatCurrency(
                          parseInt(transactionFee.payment_fee_price ?? 0)
                        )}
                      </p>
                    )}
                  </NormalText>
                </Stack>
              ) : (
                <></>
              )}
            </Box>
            {/* Grand Total */}
            <Box sx={{ background: "#FFEE8E", padding: "11px 16px" }}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <NormalText fontSize="15px" fontWeight={"bold"}>
                  Grand Total
                </NormalText>
                <NormalText fontSize="20px" fontWeight={"bold"}>
                  {formatCurrency(grandTotal)}
                </NormalText>
              </Stack>
            </Box>
            {/* Pembayaran cash */}
            {paymentMethod && paymentMethod === "cash" && (
              <>
                <Box padding={"0 15px"} margin={"10px 5px 10px 0"}>
                  <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={3.5} md={3.5}>
                      <NormalText fontSize={"14px"} style={{ width: "155px" }}>
                        Jumlah Cash
                      </NormalText>
                    </Grid>
                    <Grid item xs={8.5} md={8.5}>
                      <InputField
                        required={true}
                        type={"number"}
                        value={totalCash}
                        fullBorder={true}
                        onChange={(e: any) => {
                          setTotalCash(e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    background: "#FFEE8E",
                    padding: "5px 16px",
                    marginTop: "5px",
                  }}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <NormalText fontSize="17px" fontWeight={"bold"}>
                      Uang Kembalian
                    </NormalText>
                    <NormalText fontSize="17px" fontWeight={"bold"}>
                      {Number(totalCash) - grandTotal > 0
                        ? formatCurrency(Number(totalCash) - grandTotal)
                        : "-"}
                    </NormalText>
                  </Stack>
                </Box>
              </>
            )}
            {paymentMethod &&
              (paymentMethod === "debit" || paymentMethod === "credit") && (
                <Box padding={"0 15px"} margin={"10px 0"}>
                  {/* <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={ListBank.map((bank) => bank)}
                    renderInput={(params) => <TextField {...params} label="Pilih Bank" />}
                    sx={{marginBottom: "10px"}}
                    onChange={(event: any, value: any) => {
                      setSelectedBank(value);
                    }}
                  /> */}
                  <InputField
                    required={true}
                    label={"Masukkan No Referensi"}
                    type={"text"}
                    onChange={(e: any) => setNoRef(e.target.value)}
                    placeholder={""}
                    fullBorder={true}
                    boldText={false}
                    widthBox={"100%"}
                  />
                </Box>
              )}
            <Box padding={"0 15px"} margin={"10px 0"}>
              {/* <LoyaltyContainer>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <NormalText fontSize="14px">
                    {loyaltyAmount === 0 ? (
                      'Tidak ada point'
                    ) : (
                      <>
                        Loyalty Point Kamu{' '}
                        <span
                          style={{
                            color: '#FFC535',
                            fontFamily: 'D-DIN',
                            fontWeight: 'bold',
                          }}
                        >
                          {formatCurrency(loyaltyAmount)}
                        </span>
                      </>
                    )}
                  </NormalText>
                  <Toggle
                    disabled={loyaltyAmount === 0 ? true : false}
                    onChange={(e) => setUseLoyalty(e.target.checked)}
                  />
                </Stack>
              </LoyaltyContainer> */}
              <NormalText fontSize={"14px"} margin={"14px 5px"}>
                Pilih Pembayaran
              </NormalText>
              {paymentLoading ? (
                <Stack direction={"row"} spacing={1}>
                  <Skeleton
                    variant="rectangular"
                    height={"45px"}
                    width={"100%"}
                    sx={{ borderRadius: "5px" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height={"45px"}
                    width={"100%"}
                    sx={{ borderRadius: "5px" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height={"45px"}
                    width={"100%"}
                    sx={{ borderRadius: "5px" }}
                  />
                </Stack>
              ) : (
                <Grid container spacing={1} justifyContent={"center"}>
                  {paymentMethodList &&
                    paymentMethodList.map((channel, key) => (
                      <Grid item xs={6}>
                        <PaymentItem
                          key={key}
                          checked={paymentMethod === channel.payment_code}
                          label={channel.payment_name}
                          onChange={async () => {
                            let payFee: number = 0;
                            payFee = Number(channel.payment_fee_price);
                            if (channel.payment_fee_percentage > 0) {
                              let percent: any = channel.payment_fee_percentage;
                              let subTotal = await getCartTotalPrice();
                              let result: number = subTotal * (percent / 100);
                              payFee += Math.ceil(result);
                            }
                            //show list bank when select debit, credit, VA
                            if (
                              channel.payment_code === "debit" ||
                              channel.payment_code === "credit" ||
                              channel.payment_code === "virtual_account"
                            ) {
                              setPickBank(true);
                            }
                            setServices({
                              ...services,
                              payment_fee: payFee,
                            });
                            setPaymentDetailCheckout({
                              ...paymentDetailCheckout,
                              payment_type: channel.payment_code,
                              payment_channel: channel.payment_code,
                              payment_fee_owner: parseInt(
                                channel.payment_fee_price
                              ),
                            });
                            setPaymentMethod(channel.payment_code);
                            // reset bank when not select debit, credit, VA
                            if (
                              channel.payment_code !== "debit" &&
                              channel.payment_code !== "credit" &&
                              channel.payment_code !== "virtual_account"
                            ) {
                              setPaymentDetailCheckout({
                                ...paymentDetailCheckout,
                                bank_code: "",
                              });
                            }
                            setTransactionFee(channel);
                          }}
                        />
                      </Grid>
                    ))}
                </Grid>
              )}
              {/* <PrintReceipt /> */}
              <Button
                width={"100%"}
                variant={"primary"}
                margin={"10px 0 5px 0"}
                borderRadius={"8px"}
                style={{ letterSpacing: "0.5px" }}
                onClick={async () => await handleCheckout()}
              >
                {paymentMethod === "qr_code"
                  ? "Bayar Online"
                  : paymentMethod === "debit" || paymentMethod === "credit"
                  ? "Cetak Receipt"
                  : "Bayar"}
              </Button>
              <Button
                width={"100%"}
                variant={"outline"}
                margin={"5px 0"}
                borderRadius={"8px"}
                style={{ letterSpacing: "0.5px" }}
                onClick={() => setCancelModal(true)}
              >
                Batal
              </Button>
            </Box>
          </ContainerProduct>
          {loading && <Loader message={message} />}
        </Box>
      )}
    </MainLayout>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LoyaltyContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 10px 8px;
  margin: 0 0 11px;
`;

const ContainerProduct = styled(Box)`
  margin: 10px 0 0 0;
  width: 100%;
  overflow-y: auto;
  height: calc(100vh - 70px);
  /* @media only screen and (min-width: 1024px) {
    height: calc(100vh - 50px);
  } */
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CheckButton = styled.button`
  background: #ffffff;
  border: 1px solid #ffc535;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-family: "D-DIN";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #000000;
  align-items: center;
  padding: 5px 10px;
  margin: 10px 0 0 0;

  :hover {
    opacity: 0.8;
  }
`;

export default CheckoutPage;